<template>
  <section class="template-style-form">
    <el-form ref="form" label-width="100px">
      <el-form-item label="Background">
        <el-color-picker
          v-model="styles.background"
          @change="saveSettings()"
          show-alpha
        ></el-color-picker>
      </el-form-item>
      <el-form-item label="Border Size">
        <el-input-number
          v-model="styles.border_size"
          controls-position="right"
          :min="0"
          :max="100"
          size="small"
          style="height: auto !important;"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="Border Color">
        <el-color-picker
          v-model="styles.border_color"
          @change="saveSettings()"
          show-alpha
        ></el-color-picker>
      </el-form-item>
      <el-form-item label="Border Type">
        <el-select v-model="styles.border_type" @change="saveSettings()" size="small">
          <el-option
            v-for="(border, index) of borderTypes"
            :value="border.value"
            :label="border.name"
            :key="index"
          >
            <span style="float: left">{{ border.name }}</span>
            <span
              style="float: right; color: #8492a6; font-size: 13px"
              v-html="border.demo"
            ></span>
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item class="pt-1 save-settings-button">
        <el-button size="medium" type="primary" @click="saveSettings()">Save Settings</el-button>
      </el-form-item> -->
    </el-form>
  </section>
</template>

<script>
export default {
  name:"templates-formComponents-TemplateStyleSettings",
  props: ["data"],
  data() {
    return {
      activeName: "1",
      borderSize: 0,
      borderColor: "",
      settings: [
        {
          title: "",
        },
      ],
      borderTypes: [
        {
          name: "None",
          value: "none",
          demo: "<span style='border:none;'>abc</span>",
        },
        {
          name: "Fill",
          value: "solid",
          demo: "<span style='border:solid;'>abc</span>",
        },
        {
          name: "Dotted",
          value: "dotted",
          demo: "<span style='border:dotted;'>abc</span>",
        },
        {
          name: "Dashed",
          value: "dashed",
          demo: "<span style='border:dashed;'>abc</span>",
        },
        {
          name: "double",
          value: "Double",
          demo: "<span style='border:double;'>abc</span>",
        },
        {
          name: "3D Border groove",
          value: "groove",
          demo: "<span style='border:groove;'>abc</span>",
        },
        {
          name: "3D Border ridge",
          value: "ridge",
          demo: "<span style='border:ridge;'>abc</span>",
        },
        {
          name: "3D Border outset",
          value: "outset",
          demo: "<span style='border:outset;'>abc</span>",
        },
        {
          name: "3D Border inset",
          value: "inset",
          demo: "<span style='border:inset;'>abc</span>",
        },
      ],
      styles: {},
    };
  },
  mounted() {
    this.styles = this.data;
  },
  methods: {
    saveSettings(){
      for (const key in this.styles) {
        if(key === "background" || key === "border_size" || key === "border_color" || key === "border_type")
        {
          this.styles[key]===null ? delete this.styles[key] : this.styles[key];
        }
      }
    }
  },
};
</script>

<style lang="scss">
.template-style-form {
  .el-form-item {
    margin-bottom: 15px;
    .el-form-item__content {
      line-height: 25px;
    }
  }
  .el-form-item__label {
    text-align: left !important;
  }
}
</style>